import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'test-print-app';

  connect(): void {
    window.open('intent://printsingleline/#Intent;scheme=plaintext;package=com.zebra.browserintenturl;S.text=This is the text to print;S.verbose=true;end');
  }
}
