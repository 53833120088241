
<button type="button" (click)="connect()">
  Se connecter avec printconnect en utilisant windows open avec le browser intent
</button>
<p>
  <a id="test"
  href="intent://printsingleline/#Intent;scheme=plaintext;package=com.zebra.browserintenturl;S.text=This is the text to print;S.verbose=true;end">
  Se connecter avec printconnect avec le browser intent</a>

</p>
<p>
<a
  href="intent://zebra.com/apps/r/nfc/#Intent;scheme=http;package=com.zebra.printconnect;S.TEMPLATE_FILE_NAME=HelloWorld.zpl;end">
  Se connecter directement avec printconnect
</a>
</p>
